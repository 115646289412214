import React, {createContext, useState, useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import api from 'services/axiosConfig';

type AudioContextProviderProps = {
  children: React.ReactNode;
};

interface PlayListItem {
  CoverFile: string;
  Name: string;
  FirstCategoryName?: string;
  SecondCategoryName?: string;
  ThirdCategoryName?: string;
  AttributeValues: [];
  Song: string;
  ProductId: string;
}

const AudioContext = createContext<any>({} as AudioContextProviderProps);

export const AudioProvider = ({children}) => {
  const [playlist, setPlaylist] = useState<PlayListItem[]>([]);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [audioSrc, setAudioSrc] = useState('');
  const [isAudioBlobCreated, setIsAudioBlobCreated] = useState(false);
  const [currentTrackPlaying, setCurrentTrackPlaying] = useState({
    isAutoPlaying: false,
    id: '',
  });

  const [trackTimeDuration, setTrackTimeDuration] = useState({
    time: 0,
    duration: 0,
  });

  const location = useLocation();

  const changePlayingStatus = (id: string, playing: boolean) => {
    setCurrentTrackPlaying({
      isAutoPlaying: playing,
      id: id,
    });
  };

  const changeTimeAndDuration = (time, duration) => {
    setTrackTimeDuration({time: time, duration: duration});
  };

  const changeTrack = (newSrc: string, index = 0) => {
    setAudioSrc(newSrc);
    setCurrentTrackIndex(index);
    setIsVisible(true);
  };

  // New function to fetch and handle Blob audio
  const fetchAudioBlob = async (songUrl: string, productId: string) => {
    try {
      const response = await api.get(`products/${productId}/demo/blob`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'audio/mpeg',
        },
      }); // Fetch audio file

      const isChrome =
        /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor);

      const audioBlob = isChrome
        ? new Blob([response.data], {type: 'audio/mpeg'})
        : new Blob(response.data);

      const blobUrl = URL.createObjectURL(audioBlob); // Tworzymy lokalny URL z Blob

      return blobUrl;
    } catch (error) {
      console.error('Error fetching audio blob:', error);
      return songUrl; // Fallback to the original URL in case of error
    }
  };

  // Function to play audio, with Blob handling if needed
  const changeTrackWithBlob = async (newData: PlayListItem, index = 0) => {
    const songUrl = newData.Song;
    const productId = newData.ProductId;
    const audioBlobUrl = await fetchAudioBlob(songUrl, productId); // Fetch Blob or use URL
    changeTrack(audioBlobUrl, index); // Set the track with Blob URL or regular URL
  };

  function mergeUniqueProducts(array1: any[], array2: any[]) {
    // Łączymy tablice i tworzymy mapę, aby kluczem był `ProductId`

    const productMap = new Map();

    [...array1, ...array2].forEach((product) => {
      // Dodajemy do mapy tylko unikalne `ProductId`
      productMap.set(product.ProductId, product);
    });

    // Zwracamy tablicę unikalnych produktów
    return Array.from(productMap.values());
  }

  // Modified setPlaylistAndPlay function to handle Blobs
  const setPlaylistAndPlay = (
    newData: PlayListItem | PlayListItem[],
    startIndex = 0,
  ) => {
    if (Array.isArray(newData)) {
      // setPlaylist(newData); // Set the playlist if it's an array
      setPlaylist((prev) => mergeUniqueProducts(prev, newData));
      // changeTrackWithBlob(newData[startIndex], startIndex); // Handle Blob fetching for the first track
    } else {
      setPlaylist([newData]); // Set a playlist with one element
      changeTrackWithBlob(newData, 0); // Handle Blob fetching for the single track
    }
  };

  const closePlayer = () => {
    setAudioSrc('');
    setIsVisible(false);
  };

  const resetPlayer = () => {
    setPlaylist([]);
  };

  const playNext = () => {
    if (currentTrackIndex < playlist.length - 1) {
      const nextIndex = currentTrackIndex + 1;
      changeTrackWithBlob(playlist[nextIndex], nextIndex); // Handle Blob fetching for next track
    }
  };

  const playPrevious = () => {
    if (currentTrackIndex > 0) {
      const prevIndex = currentTrackIndex - 1;
      changeTrackWithBlob(playlist[prevIndex], prevIndex); // Handle Blob fetching for previous track
    }
  };

  const playTrackByProductId = async (productId: string) => {
    // Znajdujemy indeks na podstawie productId

    const trackIndex = playlist.findIndex(
      (track) => track.ProductId === productId,
    );

    // TODO granie dalej po pauzie z svg a nie playera
    // if (productId === currentTrackPlaying.id) {
    //   return changePlayingStatus(productId, true);
    // }

    if (trackIndex !== -1) {
      // Jeśli znajdziemy utwór, odtwarzamy go
      await changeTrackWithBlob(playlist[trackIndex], trackIndex);
    } else {
      console.error(`Track with productId: ${productId} not found`);
    }
  };

  useEffect(() => {
    closePlayer();
  }, [location.pathname]);

  return (
    <AudioContext.Provider
      value={{
        audioSrc,
        playlist,
        currentTrackIndex,
        isVisible,
        changeTrack,
        setPlaylistAndPlay,
        closePlayer,
        resetPlayer,
        playNext,
        playPrevious,
        playTrackByProductId,
        changePlayingStatus,
        currentTrackPlaying,
        changeTimeAndDuration,
        trackTimeDuration,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => useContext(AudioContext);
