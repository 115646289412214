import React, {useContext, useEffect, useRef, useState} from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {useAudio} from '../../contexts/AudioContext';
import {UserDataContext} from 'contexts/UserDataContext';
import {
  AudioPlayerWrapper,
  ContentWrapper,
  LeftContentWithImgWrapper,
  TextWrapper,
  LeftBackBtn,
  RightNextBtn,
  CloseBtn,
  CloseBtnMobile,
} from './styles';

import styled from 'styled-components';
import api from 'services/axiosConfig';
import {useCookies} from 'react-cookie';

const StyledAudioPlayer = styled(AudioPlayer)`
  /* Override default player styles */
  .rhap_container {
    height: 100px;
    background-color: ${({theme}) => theme.colors.bgWhite} !important;
    color: ${({theme}) => theme.colors.fontBlackOp70};
    box-shadow: none;
  }

  .rhap_main-controls {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
  }

  .rhap_play-pause-button {
    color: ${({theme}) => theme.colors.blueTheme};
  }

  .rhap_main-controls-button {
    color: ${({theme}) => theme.colors.blueTheme};
  }

  .rhap_button-clear {
    /* color: ${({theme}) => theme.colors.blueTheme}; */
  }
  .rhap_progress-indicator {
    background: ${({theme}) => theme.colors.blueTheme};
  }
  .rhap_progress-filled {
    background: ${({theme}) => theme.colors.blueTheme};
  }

  .rhap_time {
    color: ${({theme}) => theme.colors.fontBlackOp70} !important;
  }

  .rhap_additional-controls {
    display: none;
  }

  .rhap_progress-bar {
    /* Custom color for the progress bar */
    background-color: ${({theme}) => theme.colors.blueTheme};
  }

  .rhap_progress-section {
  }

  /* Modify other buttons */
  .rhap_button-clear {
    /* Custom color for other buttons */
  }

  /* Customizing volume controls */
  .rhap_volume-controls {
  }

  /* Customize any element based on the class it uses */
  .rhap_controls-section {
    padding: 15px; /* Modify padding */
  }

  @media (min-width: 1024px) {
    .rhap_progress-section {
      width: 1000px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 10px);
    }
  }

  @media (max-width: 1024px) {
    .rhap_controls-section {
      padding: 5px; /* Modify padding */
      justify-content: center;
      margin-top: 0px;
    }
    .rhap_volume-controls {
      display: none;
    }
    .rhap_additional-controls {
      display: none;
    }

    .rhap_main-controls {
      position: absolute;
      left: 50%;
      top: 80%;
      transform: translate(-50%, -50%);
    }
  }
`;

interface Props {
  activeTheme: string;
}

export const TrackPlayer: React.FC<Props> = ({activeTheme}) => {
  const {
    audioSrc,
    playlist,
    currentTrackIndex,
    isVisible,
    playNext,
    playPrevious,
    closePlayer,
    changePlayingStatus,
    currentTrackPlaying,
    changeTimeAndDuration,
  } = useAudio();

  const currentTrack = playlist[currentTrackIndex];

  const [isOpen, setIsOpen] = useState(isVisible);
  const [isAnimating, setIsAnimating] = useState(false);
  // const [isFollow, setIsFollow] = useState<boolean | null>(
  //   currentTrack.Favourite,
  // );

  // const [userData, setUserData] = useContext(UserDataContext);

  // const [cookies] = useCookies(['token']);

  // const unFollowOffer = async (id: string) => {
  //   try {
  //     const response = await api.delete(`Offer/favourites/${id}`, {
  //       headers: {Authorization: `Bearer ${cookies.token}`},
  //     });
  //     console.log(response.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const followOffer = async (id: string) => {
  //   try {
  //     const response = await api.post(
  //       `Offer/favourites/${id}`,
  //       {},
  //       {
  //         headers: {Authorization: `Bearer ${cookies.token}`},
  //       },
  //     );
  //     console.log(response.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const follow = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   if (userData.FirstName === null || userData.FirstName === '') {
  //     return Alert(
  //       'Aby korzytać z funkcji obserwacji, należy się zalogować',
  //       'Komunikat',
  //     );
  //   }

  //   if (isFollow === false || isFollow === null) {
  //     setIsFollow(true);
  //     followOffer(currentTrack.OfferId);
  //   }

  //   if (isFollow === true) {
  //     setIsFollow(false);
  //     unFollowOffer(currentTrack.OfferId);
  //   }

  //   // setIsFollow((prevState) => !prevState);
  // };

  useEffect(() => {
    const audioElement = playerRef.current.audio.current;

    const handleTimeUpdate = () => {
      const currentTime = audioElement.currentTime;
      // console.log(
      //   'Current Time:',
      //   currentTime,
      //   'duration:',
      //   audioElement.duration,
      // );
      changeTimeAndDuration(audioElement.currentTime, audioElement.duration);
    };

    // Sprawdzamy, czy audioElement jest dostępny
    if (audioElement) {
      // Dodajemy nasłuchiwanie zdarzenia
      audioElement.addEventListener('timeupdate', handleTimeUpdate);

      // Zwracamy funkcję czyszczącą, aby usunąć nasłuchiwanie
      return () => {
        audioElement.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      setIsAnimating(false);
    } else {
      setIsAnimating(true);

      const timeoutId = setTimeout(() => {
        setIsAnimating(false);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [isVisible]);

  const playerRef = useRef(null);

  // useEffect(() => {
  //   if (audioSrc && playerRef.current?.audio?.current) {
  //     playerRef.current.audio.current.play();
  //   }
  // }, [audioSrc]);

  const stylesForAudioPlayer =
    window.innerWidth < 1024
      ? {
          boxShadow: 'none',
          height: '95px',
          padding: '5px 15px',
          backgroundColor: activeTheme === 'light-theme' ? '#fff' : '#000000',
        }
      : {
          boxShadow: 'none',
          height: '110px',
          padding: '10px 20px',
          backgroundColor: activeTheme === 'light-theme' ? '#fff' : '#000000',
        };

  const handlePause = () => {
    if (playerRef.current.audio.current) {
      playerRef.current.audio.current.pause();
    }
  };

  // useEffect(() => {
  //   if (audioSrc && playerRef.current?.audio?.current) {
  //     if (!currentTrackPlaying.isAutoPlaying) {
  //       handlePause();
  //     }
  //   }
  // }, [currentTrackPlaying]);

  return (
    <AudioPlayerWrapper isVisible={isVisible}>
      <ContentWrapper isLight={activeTheme === 'light-theme'}>
        {window.innerWidth > 1024 && (
          <div>
            <CloseBtn
              onClick={() => {
                closePlayer();
              }}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00386 9.41816C7.61333 9.02763 7.61334 8.39447 8.00386 8.00395C8.39438 7.61342 9.02755 7.61342 9.41807 8.00395L12.0057 10.5916L14.5907 8.00657C14.9813 7.61605 15.6144 7.61605 16.0049 8.00657C16.3955 8.3971 16.3955 9.03026 16.0049 9.42079L13.4199 12.0058L16.0039 14.5897C16.3944 14.9803 16.3944 15.6134 16.0039 16.0039C15.6133 16.3945 14.9802 16.3945 14.5896 16.0039L12.0057 13.42L9.42097 16.0048C9.03045 16.3953 8.39728 16.3953 8.00676 16.0048C7.61624 15.6142 7.61624 14.9811 8.00676 14.5905L10.5915 12.0058L8.00386 9.41816Z"
                  fill="#0F0F0F"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z"
                  fill="#0F0F0F"
                />
              </svg>
            </CloseBtn>
          </div>
        )}
        {currentTrack && (
          <LeftContentWithImgWrapper>
            <img src={currentTrack.CoverFile} alt={currentTrack.Name} />
            <TextWrapper>
              <h3>{currentTrack.Name}</h3>
              <p>{`${currentTrack.FirstCategoryName || ''} ${
                currentTrack.SecondCategoryName
                  ? ` • ${currentTrack.SecondCategoryName}`
                  : ''
              } ${
                currentTrack.ThirdCategoryName
                  ? ` • ${currentTrack.ThirdCategoryName}`
                  : ''
              }`}</p>

              <p>
                {currentTrack.AttributeValues.map((item, index) => (
                  <span key={item.AttributeId}>{`${index === 0 ? '' : '• '} ${
                    item.Name
                  } `}</span>
                ))}
              </p>
            </TextWrapper>
          </LeftContentWithImgWrapper>
        )}

        <StyledAudioPlayer
          style={stylesForAudioPlayer}
          ref={playerRef}
          src={audioSrc}
          onEnded={playNext}
          showJumpControls={false}
          onPlay={() =>
            changePlayingStatus(
              currentTrack.ProductId,
              true,
              playerRef.current.audio.current,
            )
          }
          onPause={() =>
            changePlayingStatus(
              currentTrack.ProductId,
              false,
              playerRef.current.audio.current,
            )
          }
          showSkipControls={true}
          onClickPrevious={
            currentTrackIndex === 0 ? null : () => playPrevious()
          }
          onClickNext={
            currentTrackIndex === playlist.length - 1 ? null : () => playNext()
          }
          onLoadedData={() => {
            if (playerRef.current) {
              playerRef.current.audio.current.setAttribute(
                'playsInline',
                'true',
              );
            }
          }}
          onCanPlay={() => {
            console.log('Audio ready to play');
          }}
        />

        {window.innerWidth < 1024 && (
          <div>
            <CloseBtnMobile
              onClick={() => {
                closePlayer();
              }}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.00386 9.41816C7.61333 9.02763 7.61334 8.39447 8.00386 8.00395C8.39438 7.61342 9.02755 7.61342 9.41807 8.00395L12.0057 10.5916L14.5907 8.00657C14.9813 7.61605 15.6144 7.61605 16.0049 8.00657C16.3955 8.3971 16.3955 9.03026 16.0049 9.42079L13.4199 12.0058L16.0039 14.5897C16.3944 14.9803 16.3944 15.6134 16.0039 16.0039C15.6133 16.3945 14.9802 16.3945 14.5896 16.0039L12.0057 13.42L9.42097 16.0048C9.03045 16.3953 8.39728 16.3953 8.00676 16.0048C7.61624 15.6142 7.61624 14.9811 8.00676 14.5905L10.5915 12.0058L8.00386 9.41816Z"
                  fill="#0F0F0F"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z"
                  fill="#0F0F0F"
                />
              </svg>
            </CloseBtnMobile>
          </div>
        )}
      </ContentWrapper>
    </AudioPlayerWrapper>
  );
};
